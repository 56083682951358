define('supply-order-system/models/product', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
	exports['default'] = _emberDataModel['default'].extend({
		displayName: (0, _emberDataAttr['default'])('string'),
		model: (0, _emberDataAttr['default'])('string'),
		description: (0, _emberDataAttr['default'])('string'),
		cost: (0, _emberDataAttr['default'])('number'),
		price: (0, _emberDataAttr['default'])('number'),
		active: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),
		weight: (0, _emberDataAttr['default'])('number'),
		length: (0, _emberDataAttr['default'])('number'),
		width: (0, _emberDataAttr['default'])('number'),
		height: (0, _emberDataAttr['default'])('number'),
		isFreeShipping: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),
		groups: (0, _emberDataRelationships.hasMany)('group'),

		markup: (function () {
			var total = ((this.get('price') / this.get('cost') - 1) * 100).toFixed(2);
			return !isNaN(total) ? total : '0.00';
		}).property('cost', 'price')
	});
});