define('supply-order-system/models/merchant', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
	exports['default'] = _emberDataModel['default'].extend({
		dbaName: (0, _emberDataAttr['default'])('string'),
		ownerFirstName: (0, _emberDataAttr['default'])('string'),
		ownerLastName: (0, _emberDataAttr['default'])('string'),
		dbaAddress1: (0, _emberDataAttr['default'])('string'),
		dbaAddress2: (0, _emberDataAttr['default'])('string'),
		dbaCity: (0, _emberDataAttr['default'])('string'),
		dbaState: (0, _emberDataAttr['default'])('string'),
		dbaZip: (0, _emberDataAttr['default'])('string'),
		dbaPhone: (0, _emberDataAttr['default'])('string'),
		dbaEmail: (0, _emberDataAttr['default'])('string'),
		dbaFax: (0, _emberDataAttr['default'])('string'),
		status: (0, _emberDataAttr['default'])('number'),
		isoId: (0, _emberDataAttr['default'])('string'),
		isoName: (0, _emberDataAttr['default'])('string'),
		isoAddress1: (0, _emberDataAttr['default'])('string'),
		isoAddress2: (0, _emberDataAttr['default'])('string'),
		isoCity: (0, _emberDataAttr['default'])('string'),
		isoState: (0, _emberDataAttr['default'])('string'),
		isoZip: (0, _emberDataAttr['default'])('string'),
		hKey: (0, _emberDataAttr['default'])('string'),
		associate: (0, _emberDataAttr['default'])('string'),
		chain: (0, _emberDataAttr['default'])('string'),
		isoPaymentMethod: (0, _emberDataAttr['default'])('string'),
		orders: (0, _emberDataRelationships.hasMany)('order'),
		partnerLevel: (0, _emberDataAttr['default'])('string')
	});
});