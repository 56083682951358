define('supply-order-system/mirage/factories/order-datails', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	exports['default'] = _emberCliMirage.Factory.extend({
		cost: function cost() {
			return _emberCliMirage.faker.random.number();
		},
		createdAt: function createdAt() {
			return _emberCliMirage.faker.date.past();
		},
		orderId: function orderId() {
			return _emberCliMirage.faker.random.number();
		},
		price: function price() {
			return _emberCliMirage.faker.random.number();
		},
		productId: function productId() {
			return _emberCliMirage.faker.random.number();
		},
		quantity: function quantity() {
			return _emberCliMirage.faker.random.number();
		},
		updatedAt: function updatedAt() {
			return _emberCliMirage.faker.date.past();
		}
	});
});