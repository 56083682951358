define('supply-order-system/helpers/token-data-property', ['exports', 'ember'], function (exports, _ember) {
	var Helper = _ember['default'].Helper;
	var inject = _ember['default'].inject;
	var observer = _ember['default'].observer;
	var assert = _ember['default'].assert;
	exports['default'] = Helper.extend({
		session: inject.service(),
		user: inject.service(),
		onNewUser: observer('session.session.content.authenticated', function () {
			this.recompute();
		}),
		compute: function compute(params /* , hash*/) {
			assert('The token-data-property helper only accepts 1 parameter. No more, no less. For now at least.', params.length === 1);
			var user = this.get('user.userData');
			user.name = user.firstName + ' ' + user.lastName;

			if (user) {
				return user[params[0]];
			}

			return user;
		}
	});
});