define("supply-order-system/mirage/scenarios/default", ["exports"], function (exports) {
  /* eslint require-jsdoc: "off" */

  exports["default"] = function () /* server */{

    /*
      Seed your development database using your factories.
      This data will not be loaded in your tests.
    */

    // server.createList('post', 10);
  };
});