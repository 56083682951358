define('supply-order-system/controllers/application', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
	exports['default'] = _ember['default'].Controller.extend({
		socketIOService: _ember['default'].inject.service('socket-io'),
		session: _ember['default'].inject.service(),
		user: _ember['default'].inject.service(),
		init: function init() {
			this._super();
			_ember['default'].run.schedule('afterRender', this, function () {
				_ember['default'].$('.ui.dropdown.item.notifications').dropdown('set text', 'Notifications');
			});

			var socket = this.get('socketIOService').socketFor(window.location.origin + '/notifications', {
				query: 'token=' + this.get('session.data.authenticated').token
			});

			socket.on('connect', function () {
				var _this = this;

				// Immediately join the users room if authenticated
				if (this.get('user.userData').email) {
					socket.emit('join', this.get('user.userData').email);
				}

				socket.on('new', function (data) {
					_this.store.pushPayload(data);
					var notification = _this.store.peekRecord('notification', data.notification.id);
					// Check if the browser supports notifications
					if ('Notification' in window) {
						if (Notification.permission === 'granted') {
							var order = notification.get('orderId');
							var mid = order.get('mid').get('id');
							var dba = order.get('mid').get('dbaName');
							var orderId = order.get('id');
							var notificationDisplayName = notification.get('notificationActionId').get('displayName');
							new Notification('Order # ' + orderId + ' - ' + notificationDisplayName, {
								body: mid + ' - ' + dba
							});
						}
					}
				}, this);

				socket.on('notifications', function (data) {
					if (Array.isArray(data.notifications)) {
						_this.store.pushPayload(data);
						_this.set('messages', _this.store.peekAll('notification'));
					}
				}, this);
			}, this);
		},
		messages: null,
		filteredMessages: _ember['default'].computed.filterBy('messages', 'isRead', false),
		sortedMessages: _ember['default'].computed.sort('filteredMessages', function (a, b) {
			if ((0, _moment['default'])(_ember['default'].get(a, 'createdAt')) < (0, _moment['default'])(_ember['default'].get(b, 'createdAt'))) {
				//returns afternoon timings before morning timings
				//api docs says return -1, when 'a' should come before 'b'
				return 1;
			} else if ((0, _moment['default'])(_ember['default'].get(a, 'createdAt')) > (0, _moment['default'])(_ember['default'].get(b, 'createdAt'))) {
				//returns morning timings before afternoon timings
				//api doc says return 1 when 'a' should come after 'b'
				//http://emberjs.com/api/#method_computed_sort
				return -1;
			}
			return 0;
		}),
		notifications: null,
		canApprove: _ember['default'].computed('user', function () {
			return this.get('user').belongsTo('sg-supplyorder-approver@shift4.com');
		}),
		canFulfill: _ember['default'].computed('user', function () {
			return this.get('user').belongsTo('sg-supplyorder-fulfillment@shift4.com');
		}),
		canCancelPostFulfill: _ember['default'].computed('user', function () {
			return this.get('user').belongsTo('sg-supplyorder-cancel@shift4.com');
		}),
		isAdmin: _ember['default'].computed('user', function () {
			return this.get('user').belongsTo('sg-devadmin-all@shift4.com');
		}),
		actions: {
			logout: function logout() {
				this.get('session').invalidate();
			},
			resetNotificationDropdown: function resetNotificationDropdown() {
				_ember['default'].$('.ui.dropdown.item.notifications').dropdown('set text', 'Notifications');
			}
		}
	});
});