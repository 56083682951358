define('supply-order-system/instance-initializers/raven-setup', ['exports', 'ember', 'supply-order-system/config/environment', 'ember-cli-sentry/utils/parse-regex-errors'], function (exports, _ember, _supplyOrderSystemConfigEnvironment, _emberCliSentryUtilsParseRegexErrors) {
  exports.initialize = initialize;

  // Ember merge is deprecated as of 2.5, but we need to check for backwards
  // compatibility.
  var assign = _ember['default'].assign || _ember['default'].merge;

  function initialize(application) {

    if (_ember['default'].get(_supplyOrderSystemConfigEnvironment['default'], 'sentry.development') === true) {
      if (_ember['default'].get(_supplyOrderSystemConfigEnvironment['default'], 'sentry.debug') === true) {
        _ember['default'].Logger.info('`sentry` is configured for development mode.');
      }
      return;
    }

    if (!_supplyOrderSystemConfigEnvironment['default'].sentry) {
      throw new Error('`sentry` should be configured when not in development mode.');
    }

    var _config$sentry = _supplyOrderSystemConfigEnvironment['default'].sentry;
    var dsn = _config$sentry.dsn;
    var environment = _config$sentry.environment;
    var _config$sentry$debug = _config$sentry.debug;
    var debug = _config$sentry$debug === undefined ? true : _config$sentry$debug;
    var _config$sentry$includePaths = _config$sentry.includePaths;
    var includePaths = _config$sentry$includePaths === undefined ? [] : _config$sentry$includePaths;
    var _config$sentry$whitelistUrls = _config$sentry.whitelistUrls;
    var whitelistUrls = _config$sentry$whitelistUrls === undefined ? [] : _config$sentry$whitelistUrls;
    var _config$sentry$serviceName = _config$sentry.serviceName;
    var serviceName = _config$sentry$serviceName === undefined ? 'raven' : _config$sentry$serviceName;
    var _config$sentry$serviceReleaseProperty = _config$sentry.serviceReleaseProperty;
    var serviceReleaseProperty = _config$sentry$serviceReleaseProperty === undefined ? 'release' : _config$sentry$serviceReleaseProperty;
    var _config$sentry$ravenOptions = _config$sentry.ravenOptions;
    var ravenOptions = _config$sentry$ravenOptions === undefined ? {} : _config$sentry$ravenOptions;

    if (_ember['default'].get(ravenOptions, 'ignoreErrors.length')) {
      _ember['default'].set(ravenOptions, 'ignoreErrors', (0, _emberCliSentryUtilsParseRegexErrors.parseRegexErrors)(ravenOptions.ignoreErrors));
    }

    var lookupName = 'service:' + serviceName;
    var service = application.lookup ? application.lookup(lookupName) : application.container.lookup(lookupName);

    try {
      window.Raven.debug = debug;

      // Keeping existing config values for includePaths, whitelistUrls, for compatibility.
      var ravenConfig = assign({
        environment: environment,
        includePaths: includePaths,
        whitelistUrls: whitelistUrls,
        release: service.get(serviceReleaseProperty) || _supplyOrderSystemConfigEnvironment['default'].APP.version
      }, ravenOptions);

      window.Raven.config(dsn, ravenConfig);
    } catch (e) {
      _ember['default'].Logger.warn('Error during `sentry` initialization: ' + e);
      return;
    }

    window.Raven.install();

    var _config$sentry$globalErrorCatching = _supplyOrderSystemConfigEnvironment['default'].sentry.globalErrorCatching;
    var globalErrorCatching = _config$sentry$globalErrorCatching === undefined ? true : _config$sentry$globalErrorCatching;

    if (globalErrorCatching === true) {
      service.enableGlobalErrorCatching();
    }
  }

  exports['default'] = {
    initialize: initialize,
    name: 'sentry-setup'
  };
});