define('supply-order-system/routes/merchant', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	var get = _ember['default'].get;
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
		titleToken: function titleToken() {
			return 'New order';
		},
		session: _ember['default'].inject.service(),
		user: _ember['default'].inject.service(),
		model: function model(params) {
			if (params.id.length > 5) {
				this.store.unloadAll();
				return _ember['default'].RSVP.hash({
					merchant: this.store.find('merchant', params.id),
					bankAccounts: this.store.query('bankAccount', { mid: params.id }),
					billingMethods: this.store.findAll('billingMethod'),
					order: this.store.createRecord('order', {
						createdBy: this.get('user.userData').email
					}),
					groups: this.store.findAll('group'),
					comment: this.store.createRecord('orderComment', {
						comment: '',
						createdBy: this.get('user.userData').email
					}),
					shippingMethods: this.store.findAll('shippingMethod'),
					office: this.store.queryRecord('office', { mid: params.id }),
					brands: this.store.findAll('brand')
				});
			}
			return _ember['default'].RSVP.hash({
				office: this.store.find('office', params.id),
				order: this.store.createRecord('order', {
					createdBy: this.get('user.userData').email
				}),
				billingMethods: this.store.findRecord('billingMethod', 2),
				groups: this.store.findAll('group'),
				comment: this.store.createRecord('orderComment', {
					comment: '',
					createdBy: this.get('user.userData').email
				}),
				shippingMethods: this.store.findAll('shippingMethod')
			});
		},
		afterModel: function afterModel(model) {
			if (model.merchant) {
				var defaultBrand = this.store.peekRecord('brand', 1);

				model.order.setProperties({
					mid: model.merchant,
					officeId: model.office,
					isGraphite: model.merchant.get('hKey') === '025',
					shippingAttention: model.merchant.get('ownerFirstName') + ' ' + model.merchant.get('ownerLastName'),
					shippingTo: model.merchant.get('dbaName'),
					shippingAddress1: model.merchant.get('dbaAddress1'),
					shippingAddress2: model.merchant.get('dbaAddress2'),
					shippingCity: model.merchant.get('dbaCity'),
					shippingState: model.merchant.get('dbaState'),
					shippingZip: model.merchant.get('dbaZip'),
					shippingZipExtended: '',
					quantumViewRecipient: model.merchant.get('dbaEmail'),
					brandId: model.brands.find(function (brand) {
						return get(brand, 'chain') === get(model.merchant, 'chain') && get(brand, 'associate') === get(model.merchant, 'associate');
					}) || defaultBrand
				});
			} else {
				model.order.setProperties({
					isGraphite: model.office.get('hKey') === '025',
					isIsoOrder: true,
					billingMethodId: model.billingMethods,
					officeId: model.office,
					shippingTo: model.office.get('isoName'),
					shippingAddress1: model.office.get('isoAddress1'),
					shippingAddress2: model.office.get('isoAddress2'),
					shippingCity: model.office.get('isoCity'),
					shippingState: model.office.get('isoState'),
					shippingZip: model.office.get('isoZip'),
					shippingZipExtended: ''
				});
			}
			model.order.get('orderComments').pushObject(model.comment);
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			controller.set('now', new Date());
			controller.setDefaultValues();
		}
	});
});