define('supply-order-system/models/order', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-api-actions'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships, _emberApiActions) {
	exports['default'] = _emberDataModel['default'].extend({
		mid: (0, _emberDataRelationships.belongsTo)('merchant'),
		officeId: (0, _emberDataRelationships.belongsTo)('office'),
		isGraphite: (0, _emberDataAttr['default'])('boolean'),
		shippingAttention: (0, _emberDataAttr['default'])('string'),
		shippingTo: (0, _emberDataAttr['default'])('string'),
		shippingAddress1: (0, _emberDataAttr['default'])('string'),
		shippingAddress2: (0, _emberDataAttr['default'])('string'),
		shippingCity: (0, _emberDataAttr['default'])('string'),
		shippingState: (0, _emberDataAttr['default'])('string'),
		shippingZip: (0, _emberDataAttr['default'])('string'),
		shippingZipExtended: (0, _emberDataAttr['default'])('string'),
		quantumViewRecipient: (0, _emberDataAttr['default'])('string'),
		isAddressValidated: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),
		approvedBy: (0, _emberDataAttr['default'])('string'),
		approvedAt: (0, _emberDataAttr['default'])('date'),
		deniedReason: (0, _emberDataAttr['default'])('string'),
		fulfilledBy: (0, _emberDataAttr['default'])('string'),
		fulfilledAt: (0, _emberDataAttr['default'])('date'),
		cancelledBy: (0, _emberDataAttr['default'])('string'),
		cancelledAt: (0, _emberDataAttr['default'])('date'),
		cancelledReason: (0, _emberDataAttr['default'])('string'),
		createdBy: (0, _emberDataAttr['default'])('string'),
		discount: (0, _emberDataAttr['default'])('number', { defaultValue: 0 }),
		createdAt: (0, _emberDataAttr['default'])('date'),
		isBilled: (0, _emberDataAttr['default'])('boolean'),
		billedAt: (0, _emberDataAttr['default'])('date'),
		shippingPrice: (0, _emberDataAttr['default'])('number', { defaultValue: 0 }),
		isIsoOrder: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),
		orderDetails: (0, _emberDataRelationships.hasMany)('orderDetail'),
		orderComments: (0, _emberDataRelationships.hasMany)('orderComment'),
		billingMethodId: (0, _emberDataRelationships.belongsTo)('billingMethod'),
		brandId: (0, _emberDataRelationships.belongsTo)('brand'),
		shippingMethodId: (0, _emberDataRelationships.belongsTo)('shippingMethod'),
		orderTrackings: (0, _emberDataRelationships.hasMany)('orderTracking'),
		notifications: (0, _emberDataRelationships.hasMany)('notification'),
		orderStatusId: (0, _emberDataRelationships.belongsTo)('orderStatus'),
		addressId: (0, _emberDataAttr['default'])('string'),
		shippingOrderId: (0, _emberDataAttr['default'])('string'),
		bankAccountID: (0, _emberDataAttr['default'])('string'),

		total: (function () {
			var total = 0;
			this.get('orderDetails').forEach(function (detail) {
				total += +detail.get('price') * +detail.get('quantity');
			});

			total += +this.get('shippingPrice');
			return total.toFixed(2);
		}).property('orderDetails.@each.cost', 'shippingPrice'),

		totalAfterDiscount: (function () {
			return this.get('total') - this.get('discount');
		}).property('total', 'discount'),

		fulfill: (0, _emberApiActions.memberAction)({ path: 'fulfill' }),
		approve: (0, _emberApiActions.memberAction)({ path: 'approve' }),
		deny: (0, _emberApiActions.memberAction)({ path: 'deny' }),
		cancel: (0, _emberApiActions.memberAction)({ path: 'cancel' })
	});
});