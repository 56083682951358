define('supply-order-system/mirage/factories/group', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	exports['default'] = _emberCliMirage.Factory.extend({
		active: function active() {
			return _emberCliMirage.faker.boolean();
		},
		displayName: function displayName() {
			return _emberCliMirage.faker.lorem();
		},
		groupProduct: function groupProduct() {
			return {
				groupId: _emberCliMirage.faker.random.number(),
				productId: _emberCliMirage.faker.random.number({ min: 0, max: 10 })
			};
		}
	});
});