define('supply-order-system/components/ht-body', ['exports', 'ember', 'ember-get-config'], function (exports, _ember, _emberGetConfig) {
	var APP = _emberGetConfig['default'].APP;
	var Component = _ember['default'].Component;
	var inject = _ember['default'].inject;

	/**
  * Boilerplate body for internal applications
  * @class ht-body
  * @namespace Component
  * @extends Ember.Component
  */
	exports['default'] = Component.extend({
		tagName: 'div',
		currentSite: APP.name,
		session: inject.service(),
		actions: {
			navigate: function navigate(url) {
				window.open(url, '_blank');
			},
			authenticate: function authenticate() {
				this.sendAction(this.get('identification'), this.get('password'));
			}
		}
	});
});