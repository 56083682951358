define('supply-order-system/models/order-detail', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
	exports['default'] = _emberDataModel['default'].extend({
		quantity: (0, _emberDataAttr['default'])('string'),
		cost: (0, _emberDataAttr['default'])('number'),
		price: (0, _emberDataAttr['default'])('number'),
		orderId: (0, _emberDataRelationships.belongsTo)('order'),
		productId: (0, _emberDataRelationships.belongsTo)('product'),

		lineTotal: (function () {
			var total = 0;
			total += +(+this.get('productId.cost') * +this.get('quantity')).toFixed(2);
			return total;
		}).property('price', 'quantity', 'productId.cost')
	});
});