define('supply-order-system/components/pagination-navigation', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		pages: _ember['default'].computed('count', 'current', function () {
			var pageArray = [];
			var pageCount = Math.ceil(this.get('count') / this.get('perPage'));
			var i = 0;
			while (i < pageCount) {
				i++;
				if (i !== this.get('current')) {
					pageArray.push(i);
				}
			}
			return { array: pageArray, total: pageCount || 1 };
		}),
		actions: {
			backPage: function backPage() {
				this.decrementProperty('current');
			},
			nextPage: function nextPage() {
				this.incrementProperty('current');
			},
			updatePage: function updatePage(element, value) {
				// This if statement prevents an infinite loop of the component updating itself
				if (value > 0) {
					this.set('current', value);
					// We don't want the new page shown in the dropdown
					// This line causes the infinite loop
					_ember['default'].$('#pagination').dropdown('clear');
					_ember['default'].$('#pagination').dropdown('restore default text');
				}
			}
		}
	});
});