define('supply-order-system/authenticators/sso', ['exports', 'ember', 'ember-simple-auth/authenticators/base', 'supply-order-system/config/environment'], function (exports, _ember, _emberSimpleAuthAuthenticatorsBase, _supplyOrderSystemConfigEnvironment) {
	var reject = _ember['default'].RSVP.reject;
	exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend({
		ajax: _ember['default'].inject.service(),
		user: _ember['default'].inject.service(),
		restore: function restore(_ref) {
			var token = _ref.token;

			return token ? this.get('user').fetchUserData(token).then(function () {
				return { token: token };
			}) : reject();
		},
		authenticate: function authenticate(token) {
			return token ? this.get('user').fetchUserData(token).then(function () {
				return { token: token };
			}) : reject();
		},
		invalidate: function invalidate() {
			return this.get('ajax').post(_supplyOrderSystemConfigEnvironment['default'].host + '/api/v1/auth/logout');
		}
	});
});