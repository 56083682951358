define('supply-order-system/routes/merchants', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
		titleToken: function titleToken() {
			return 'Account lookup';
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			controller.set('search', null);
		}
	});
});