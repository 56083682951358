define('supply-order-system/mirage/routes/order-statuses', ['exports'], function (exports) {
	/* eslint require-jsdoc: "off" */

	exports['default'] = function (server) {
		server.get('/orderStatuses', function (_ref) {
			var orderStatuses = _ref.orderStatuses;

			return {
				orderStatuses: orderStatuses.all().models,
				meta: {
					itemsCount: orderStatuses.all().models.length,
					pagesCount: Math.ceil(orderStatuses.all().models.length / 10)
				}
			};
		});
	};
});