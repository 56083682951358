define('supply-order-system/routes/approvals/merchant', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

	var RSVP = _ember['default'].RSVP;
	var Route = _ember['default'].Route;
	exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
		titleToken: function titleToken() {
			return 'Merchant';
		},
		model: function model(params) {
			return RSVP.hash({
				orders: this.store.query('order', Object.assign.apply(Object, _toConsumableArray(params).concat([{
					orderStatusId: 1,
					isIsoOrder: false
				}]))),
				orderStatuses: this.store.findAll('orderStatus'),
				brands: this.store.findAll('brand')
			});
		}
	});
});