define('supply-order-system/controllers/approvals', ['exports', 'ember'], function (exports, _ember) {
	var get = _ember['default'].get;
	var computed = _ember['default'].computed;
	exports['default'] = _ember['default'].Controller.extend({
		columns: computed('model', function () {
			return [{
				propertyName: 'id',
				title: 'ID',
				mayBeHidden: false,
				theadClassName: 'collapsing',
				routeName: 'approval'
			}, {
				propertyName: 'orderStatusId.displayName',
				filteredBy: 'orderStatusId',
				title: 'Status',
				template: 'ember-common-table/order/order-status-id',
				filterWithSelect: true,
				type: 'number',
				predefinedFilterOptions: get(this, 'model.orderStatuses').map(function (x) {
					return { label: get(x, 'displayName'), value: get(x, 'id') };
				})
			}, {
				propertyName: 'isIsoOrder',
				title: 'Account Type',
				template: 'ember-common-table/order/is-iso-order',
				filterWithSelect: true,
				type: 'boolean',
				predefinedFilterOptions: [{ label: 'Merchant', value: false }, { label: 'ISO', value: true }],
				disableSorting: true
			}, {
				propertyName: 'mid.id',
				filteredBy: 'mid',
				title: 'MID'
			}, {
				propertyName: 'officeId.id',
				filteredBy: 'officeId',
				title: 'Office'
			}, {
				propertyName: 'createdBy',
				title: 'Created By'
			}, {
				propertyName: 'createdAt',
				title: 'Created At'
			}, {
				propertyName: 'orderDetails.firstObject.productId.displayName',
				title: 'First Item In Cart',
				disableFiltering: true,
				disableSorting: true
			}];
		})
	});
});