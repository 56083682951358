define('supply-order-system/mirage/factories/product', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	exports['default'] = _emberCliMirage.Factory.extend({
		active: function active() {
			return _emberCliMirage.faker.boolean();
		},
		cost: function cost() {
			return _emberCliMirage.faker.random.number();
		},
		createdAt: function createdAt() {
			return _emberCliMirage.faker.date.past();
		},
		description: function description() {
			return _emberCliMirage.faker.lorem.title();
		},
		displayName: function displayName() {
			return _emberCliMirage.faker.name.firstName();
		},
		height: function height() {
			return _emberCliMirage.faker.random.number();
		},
		imageLink: function imageLink() {
			return _emberCliMirage.faker.url();
		},
		isFreeShipping: function isFreeShipping() {
			return _emberCliMirage.faker.boolean();
		},
		length: function length() {
			return _emberCliMirage.faker.random.number();
		},
		model: function model() {
			return _emberCliMirage.faker.lorem.word();
		},
		price: function price() {
			return _emberCliMirage.faker.random.number();
		},
		updatedAt: function updatedAt() {
			return _emberCliMirage.faker.date();
		},
		weight: function weight() {
			return _emberCliMirage.faker.random.number();
		},
		width: function width() {
			return _emberCliMirage.faker.random.number();
		}
	});
});