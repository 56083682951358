define('supply-order-system/models/shipping-order', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
	exports['default'] = _emberDataModel['default'].extend({
		rate: (0, _emberDataAttr['default'])('number'),
		publishedRate: (0, _emberDataAttr['default'])('number'),
		trackingNumber: (0, _emberDataAttr['default'])('string'),
		isSaturdayDelivery: (0, _emberDataAttr['default'])('boolean'),
		labelImage: (0, _emberDataAttr['default'])('string'),
		addressId: (0, _emberDataRelationships.belongsTo)('address'),
		serviceId: (0, _emberDataRelationships.belongsTo)('shippingMethod'),
		orderId: (0, _emberDataRelationships.belongsTo)('order')
	});
});