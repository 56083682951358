define('supply-order-system/routes/orders/all', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	var RSVP = _ember['default'].RSVP;
	var Route = _ember['default'].Route;
	exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
		titleToken: function titleToken() {
			return 'All';
		},
		model: function model(params) {
			return RSVP.hash({
				orders: this.store.query('order', params),
				orderStatuses: this.store.findAll('orderStatus'),
				brands: this.store.findAll('brand')
			});
		}
	});
});