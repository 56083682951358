define('supply-order-system/controllers/admin/products', ['exports', 'ember'], function (exports, _ember) {
	var get = _ember['default'].get;
	var set = _ember['default'].set;
	var computed = _ember['default'].computed;
	exports['default'] = _ember['default'].Controller.extend({
		newProductMarkup: computed('newProduct.cost', 'newProduct.price', function () {
			var total = ((get(this, 'newProduct.price') / get(this, 'newProduct.cost') - 1) * 100).toFixed(2);
			return !isNaN(total) ? total : '0.00';
		}),
		expandedRowTemplate: 'ember-common-table/admin/products/edit',
		columns: computed('model', function () {
			return [{
				template: 'ember-common-table/admin/products/name-column',
				propertyName: 'displayName',
				title: 'Name'
			}, {
				mayBeHidden: false,
				template: 'ember-common-table/edit-row',
				className: 'collapsing'
			}];
		}),
		actions: {
			showModal: function showModal(modal) {
				set(this, 'newProduct', {});
				_ember['default'].$('.ui.' + modal + '.modal').modal('show');
			}
		}
	});
});