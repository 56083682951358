define('supply-order-system/authenticators/ember-common', ['exports', 'ember', 'ember-simple-auth/authenticators/base'], function (exports, _ember, _emberSimpleAuthAuthenticatorsBase) {
	exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend({
		restore: function restore(data) {
			return new Promise(function (resolve, reject) {
				// Really should validate the token to see
				// about the expiration time
				return resolve(true);
			});
		},
		authenticate: function authenticate(options) {
			return new Promise(function (resolve, reject) {
				if (options.code && options.scope && options.clientId) {
					options.redirectUri = 'http://127.0.0.1:8081/callback';
					_ember['default'].$.ajax({
						url: 'http://127.0.0.1:8081/oauth/token/access',
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						dataType: 'json',
						data: JSON.stringify(options)
					}).success(function (data) {
						return resolve(data);
					}).fail(function () {
						return reject();
					});
				} else {
					window.location.replace('http://127.0.0.1:8081/#/sign-in?clientId=4&redirectUri=' + window.location.protocol + '//' + window.location.host + '/callback&grantType=a&scopes=123,123,123,123,123,213');
				}
			});
		},
		invalidate: function invalidate(data) {
			return new Promise(function (resolve, reject) {
				return resolve(true);
			});
		}
	});
});