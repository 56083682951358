define('supply-order-system/mirage/factories/brand', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	exports['default'] = _emberCliMirage.Factory.extend({
		name: function name(i) {
			return _emberCliMirage.faker.list.cycle('Lighthouse Network', 'Restaurant Manager')(i);
		},
		logoUrl: function logoUrl(i) {
			return _emberCliMirage.faker.list.cycle('https://s3.amazonaws.com/ht-supply-system/logos/lighthouse.png', 'https://s3.amazonaws.com/ht-supply-system/logos/rm.png')(i);
		},
		chain: 'test',
		emailSender: 'test',
		emailFooter: 'test'
	});
});