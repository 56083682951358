define('supply-order-system/routes/callback', ['exports', 'ember'], function (exports, _ember) {
  // import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend();

  // export default Ember.Route.extend(UnauthenticatedRouteMixin, {
  // 	session: Ember.inject.service(),
  // 	beforeModel(transition) {
  // 		if (!this.get('session.isAuthenticated')) {
  // 			let { code, scope } = transition.queryParams;
  // 			if (!code || !scope) {
  // 				throw new Error('invalid_parameters');
  // 			}
  // 			this.get('session').authenticate('authenticator:custom', transition.queryParams);
  // 		}
  // 	}
  // });
});