define('supply-order-system/routes/invoice', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
		titleToken: function titleToken(model) {
			return 'Invoice #' + model.id;
		},
		model: function model(params) {
			return this.store.findRecord('order', params.id);
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			controller.set('now', new Date());
		}
	});
});