define('supply-order-system/routes/admin/groups', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	var get = _ember['default'].get;
	var set = _ember['default'].set;
	var Route = _ember['default'].Route;
	exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
		titleToken: function titleToken() {
			return 'Groups';
		},
		model: function model() {
			return this.store.query('group', { includeDisabled: true }, { reload: true });
		},
		setupController: function setupController(controller) {
			this._super.apply(this, arguments);
			set(this, 'controller', controller);
		},
		actions: {
			save: function save(group) {
				var _this = this;

				return group.save().then(function () {
					return _this.notifications.success('Group saved');
				})['catch'](function (err) {
					return _this.notifications.error(get(err, 'errors.message'));
				});
			},
			reset: function reset(group) {
				return group.rollbackAttributes();
			},
			create: function create() {
				var _this2 = this;

				return this.store.createRecord('group', get(this, 'controller.newGroup')).save().then(function () {
					_this2.notifications.success('Group saved');
					return _this2.refresh();
				})['catch'](function (err) {
					return _this2.notifications.error(get(err, 'errors.message'));
				});
			}
		}
	});
});