define('supply-order-system/routes/approval', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'supply-order-system/mixins/routes/required-group'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _supplyOrderSystemMixinsRoutesRequiredGroup) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _supplyOrderSystemMixinsRoutesRequiredGroup['default'], {
		requiredGroup: 'sg-supplyorder-approver@shift4.com',
		titleToken: function titleToken(model) {
			return 'Approve #' + model.id;
		},
		beforeModel: function beforeModel(transition) {
			if (!this.controllerFor('application').get('canApprove')) {
				if (transition.state.params.approval.id) {
					this.transitionTo('order', transition.state.params.approval.id);
				} else {
					this.transitionTo('orders.all');
				}
			}
		},
		model: function model(params) {
			return this.store.findRecord('order', params.id);
		},
		afterModel: function afterModel() {
			this.store.findAll('orderStatus');
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			controller.set('deniedReason', null);
		}
	});
});