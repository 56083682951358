define('supply-order-system/adapters/application', ['exports', 'ember', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _ember, _emberData, _emberSimpleAuthMixinsDataAdapterMixin) {

	var HEADERS = {
		'Cache-Control': 'no-cache',
		Pragma: 'no-cache',
		Expires: 0
	};

	exports['default'] = _emberData['default'].RESTAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
		session: _ember['default'].inject.service(),
		headers: _ember['default'].computed(function () {
			var _get = this.get('session.data.authenticated');

			var token = _get.token;

			if (_ember['default'].isPresent(token)) {
				HEADERS['auth-token'] = token;
			}

			return HEADERS;
		}).volatile(),
		authorizer: 'authorizer:token',
		handleResponse: function handleResponse(status) {
			// if we get back a 401, then we should invalidate the session
			if (shouldInvalidateSession(status, this.get('session'))) {
				this.get('session').invalidate();
			}

			return this._super.apply(this, arguments);
		},
		namespace: '/api/v1'
	});

	/**
  * Checks if session has to be invalidated.
  *
  * @param {Number} status - status code of response
  * @param {Object} session - session service
  * @returns {Boolean} check result
  */
	function shouldInvalidateSession(status, session) {
		return status === 401 && session.get('isAuthenticated');
	}
});