define('supply-order-system/routes/login', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		ajax: _ember['default'].inject.service(),
		session: _ember['default'].inject.service(),
		beforeModel: function beforeModel(transition) {
			var _this = this;

			if (this.get('session.isAuthenticated')) {
				return this.transitionTo('/');
			}

			var err = transition.queryParams.error;
			if (err) {
				this.notifications.error(err);
			}

			return this.get('session').authenticate('authenticator:sso', transition.queryParams.token).then(function () {
				transition.abort();
				_this.transitionTo('/');
			})['catch'](function () {
				_this.transitionTo('login');
			});
		},
		model: function model() {
			var callback = encodeURIComponent(window.location.origin + '/#/login');
			return _ember['default'].RSVP.hash({
				authUrl: this.get('ajax').request(window.location.origin + '/api/v1/auth/url?callback=' + callback).then(function (_ref) {
					var url = _ref.url;
					return url;
				})
			});
		}
	});
});