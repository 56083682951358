define('supply-order-system/serializers/order', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
		attrs: {
			orderDetails: { serialize: 'records', deserialize: 'records' },
			orderComments: { serialize: 'records', deserialize: 'records' },
			orderTrackings: { serialize: false, deserialize: 'records' },
			orderStatusId: { serialize: 'id', deserialize: 'id' },
			brandId: { serialize: 'ids', deserialize: 'ids' },
			bankAccountID: { serialize: 'records', deserialize: 'records' }
		}
	});
});