define('supply-order-system/controllers/approval', ['exports', 'ember'], function (exports, _ember) {
	var get = _ember['default'].get;
	exports['default'] = _ember['default'].Controller.extend({
		session: _ember['default'].inject.service(),
		user: _ember['default'].inject.service(),
		addingComment: false,
		comment: null,
		showDenyModal: false,
		showApproveModal: false,
		showManualReason: false,
		deniedReasons: ['Duplicate', 'Incorrect Product', 'Incorrect Product Price', 'Incorrect Shipping Address', 'Incorrect Shipping Method', 'Incorrect Shipping Price'],
		deniedReason: null,
		actions: {
			toggle: function toggle(property) {
				this.toggleProperty(property);
			},
			showModal: function showModal(modal) {
				_ember['default'].$('.ui.' + modal + '.modal').modal('show');
			},
			approve: function approve(approved) {
				var _this = this;

				this.loader.show('The order is being saved');
				var order = this.get('model');

				if (approved) {
					order.approve().then(function () {
						_this.notifications.success('Order updated');
						_this.transitionToRoute('approvals.all');
					})['catch'](function (err) {
						_this.notifications.error(get(err, 'errors.message') || 'Error while saving order');
					}).then(function () {
						return _this.loader.clear();
					});
				} else {
					order.deny({ deniedReason: this.get('deniedReason') }).then(function () {
						_this.notifications.success('Order updated');
						_this.transitionToRoute('approvals.all');
					})['catch'](function (err) {
						_this.notifications.error(get(err, 'errors.message') || 'Error while saving order');
					}).then(function () {
						return _this.loader.clear();
					});
				}
			},
			addComment: function addComment() {
				var _this2 = this;

				this.set('addingComment', true);
				var comment = this.store.createRecord('orderComment', {
					comment: this.get('comment'),
					createdBy: this.get('user.userData').email,
					orderId: this.get('model')
				});
				comment.save().then(function () {
					_this2.set('comment', null);
					_this2.set('addingComment', false);
					_this2.notifications.success('Comment created');
				})['catch'](function (err) {
					_this2.set('addingComment', false);
					_this2.notifications.error(get(err, 'errors.message') || 'Error while creating comment');
					comment.deleteRecord();
				});
			}
		}
	});
});