define('supply-order-system/components/status-label', ['exports', 'ember', 'supply-order-system/enums/statusColors'], function (exports, _ember, _supplyOrderSystemEnumsStatusColors) {
	exports['default'] = _ember['default'].Component.extend({
		status: null,
		statusColor: _ember['default'].computed('status', function () {
			var status = this.get('status');
			return _supplyOrderSystemEnumsStatusColors['default'].find(function (item) {
				return item.id === status.get('id');
			}).color || '';
		})
	});
});