define('supply-order-system/components/navigable-select', ['exports', 'ember'], function (exports, _ember) {
	var get = _ember['default'].get;
	var computed = _ember['default'].computed;
	exports['default'] = _ember['default'].Component.extend({
		// CPs
		transformedOptions: computed('options', function () {
			return (function walker(options) {
				var parentLevel = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

				var results = _ember['default'].A();

				// this is necessary because power-select calls `toArray`, which
				// makes a copy and breaks our ability to compare parentLevel
				// via `===`.
				results.toArray = function () {
					return results;
				};

				var len = get(options, 'length');
				parentLevel = parentLevel || { root: true };
				for (var i = 0; i < len; i++) {
					var opt = get(options, '' + i);
					var groupName = get(opt, 'groupName');
					if (groupName) {
						var level = { levelName: groupName };
						var optionsWithBack = _ember['default'].A([{ parentLevel: parentLevel }]).concat(get(opt, 'options'));
						level.options = walker(optionsWithBack, level);
						results.push(level);
					} else {
						results.push(opt);
					}
				}
				parentLevel.options = results;
				return results;
			})(this.get('options'));
		}),

		currentOptions: computed.oneWay('transformedOptions'),

		// Actions
		actions: {
			onchange: function onchange(levelOrOption, dropdown) {
				if (get(levelOrOption, 'levelName')) {
					this.set('currentOptions', get(levelOrOption, 'options'));
				} else if (levelOrOption.parentLevel) {
					this.set('currentOptions', levelOrOption.parentLevel.options);
				} else {
					this.get('onchange')(levelOrOption);
					dropdown.actions.close();
					this.set('currentOptions', this.get('transformedOptions'));
				}
			},

			search: function search(term) {
				var normalizedTerm = term.toLowerCase();
				var results = this.get('currentOptions').filter(function (o) {
					if (o.parentLevel) {
						return normalizedTerm === '';
					} else if (get(o, 'levelName')) {
						return get(o, 'levelName').toLowerCase().indexOf(normalizedTerm) > -1;
					}
					return o.toLowerCase().indexOf(normalizedTerm) > -1;
				});
				results.fromSearch = true;
				return results;
			}
		}
	});
});