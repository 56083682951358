define('supply-order-system/routes/order', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
		titleToken: function titleToken(model) {
			return 'Order #' + model.id;
		},
		model: function model(params) {
			var existingModel = this.store.peekRecord('order', params.id);

			if (existingModel) {
				this.store.unloadRecord(existingModel);
			}

			return this.store.findRecord('order', params.id);
		},
		afterModel: function afterModel(model) {
			model.reload();
			model.get('notifications').forEach(function (notification) {
				notification.set('isRead', true);
				notification.save();
			});
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);
			controller.set('cancelledReason', null);
		}
	});
});