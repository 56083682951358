define('supply-order-system/instance-initializers/ember-intl', ['exports', 'ember'], function (exports, _ember) {
  exports.instanceInitializer = instanceInitializer;

  function instanceInitializer(instance) {
    _ember['default'].deprecate('[ember-intl] instance initializer is deprecated, no longer necessary to call in testing.', false, {
      id: 'ember-intl-instance-initalizer'
    });
  }

  exports['default'] = {
    name: 'ember-intl',
    initialize: function initialize() {}
  };
});
/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */