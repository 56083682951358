define('supply-order-system/mirage/factories/merchant', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	exports['default'] = _emberCliMirage.Factory.extend({
		associate: function associate() {
			_emberCliMirage.faker.random.number().substring(0, 3);
		},
		chain: function chain() {
			_emberCliMirage.faker.random.number().substring(0, 3);
		},
		dbaAddress1: function dbaAddress1() {
			return _emberCliMirage.faker.address.streetAddress();
		},
		dbaAddress2: function dbaAddress2() {
			return _emberCliMirage.faker.address.streetAddress();
		},
		dbaCity: function dbaCity() {
			return _emberCliMirage.faker.address.city();
		},
		dbaEmail: function dbaEmail() {
			return _emberCliMirage.faker.email();
		},
		dbaFax: function dbaFax() {
			_emberCliMirage.faker.phone();
		},
		dbaName: function dbaName() {
			_emberCliMirage.faker.name.firstName();
		},
		dbaPhone: function dbaPhone() {
			_emberCliMirage.faker.phone();
		},
		dbaState: function dbaState() {
			return _emberCliMirage.faker.address.state();
		},
		dbaZip: function dbaZip() {
			return _emberCliMirage.faker.address.zipCode().substring(0, 5);
		},
		hKey: function hKey() {
			_emberCliMirage.faker.random.number().substring(0, 3);
		},
		id: function id() {
			_emberCliMirage.faker.random.number().substring(0, 10);
		},
		isoAddress1: function isoAddress1() {
			return _emberCliMirage.faker.address.streetAddress();
		},
		isoAddress2: function isoAddress2() {
			return _emberCliMirage.faker.address.streetAddress();
		},
		isoCity: function isoCity() {
			return _emberCliMirage.faker.address.city();
		},
		isoId: function isoId() {
			_emberCliMirage.faker.random.number().substring(0, 10);
		},
		isoName: function isoName() {
			return _emberCliMirage.faker.name.firstName() + ' ' + _emberCliMirage.faker.name.lastName();
		},
		isoPaymentMethod: function isoPaymentMethod() {
			return _emberCliMirage.faker.list.cycle('Regular Mail', 'ACH', '');
		},
		isoState: function isoState() {
			return _emberCliMirage.faker.address.state();
		},
		isoZip: function isoZip() {
			return _emberCliMirage.faker.address.zipCode().substring(0, 5);
		},
		ownerFirstName: function ownerFirstName() {
			_emberCliMirage.faker.name.firstName();
		},
		ownerLastName: function ownerLastName() {
			_emberCliMirage.faker.name.lastName();
		},
		status: function status() {
			return _emberCliMirage.faker.list.cycle('700', '600', '500');
		}
	});
});