define('supply-order-system/enums/statusColors', ['exports'], function (exports) {
	exports['default'] = [{
		id: '1',
		color: 'yellow'
	}, {
		id: '2',
		color: 'orange'
	}, {
		id: '3',
		color: 'red'
	}, {
		id: '4',
		color: 'red'
	}, {
		id: '5',
		color: 'green'
	}, {
		id: '6',
		color: 'red'
	}];
});