define('supply-order-system/mirage/routes/orders', ['exports'], function (exports) {
	/* eslint require-jsdoc: "off" */

	exports['default'] = function (server) {
		server.get('/orders', function (_ref) {
			var orders = _ref.orders;

			return {
				orders: orders.all().models,
				meta: {
					itemsCount: orders.all().models.length,
					pagesCount: Math.ceil(orders.all().models.length / 10)
				}
			};
		});
	};
});