define('supply-order-system/components/ht-menu', ['exports', 'ember'], function (exports, _ember) {
	var Component = _ember['default'].Component;
	var inject = _ember['default'].inject;
	var run = _ember['default'].run;
	var $ = _ember['default'].$;
	exports['default'] = Component.extend({
		tagName: 'div',
		classNames: ['ht-nav'],
		session: inject.service(),
		didRender: function didRender() {
			/**
    *  We need wait to apply this setting so the pusher can be added and
    *  the sidebar can be moved beforehand
    */
			run.later(this, function () {
				$('#sub-sidebar').sidebar('setting', 'transition', 'overlay');
			}, 1000);
		},
		actions: {
			logout: function logout() {
				this.get('session').invalidate();
			},
			toggleSidebar: function toggleSidebar() {
				$('#sub-sidebar').sidebar('toggle');
			}
		}
	});
});