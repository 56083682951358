define('supply-order-system/services/ajax', ['exports', 'ember', 'ember-ajax/services/ajax', 'supply-order-system/config/environment'], function (exports, _ember, _emberAjaxServicesAjax, _supplyOrderSystemConfigEnvironment) {
	exports['default'] = _emberAjaxServicesAjax['default'].extend({
		namespace: '/api/v1/external',
		host: _supplyOrderSystemConfigEnvironment['default'].host,
		session: _ember['default'].inject.service(),
		contentType: 'application/json',
		headers: _ember['default'].computed('session.data', 'session.isAuthenticated', function () {
			return this.get('session.isAuthenticated') ? { 'auth-token': this.get('session.data.authenticated').token } : {};
		}),
		handleResponse: function handleResponse(status) {
			// if we get back a 401, then we should invalidate the session
			if (shouldInvalidateSession(status, this.get('session'))) {
				this.get('session').invalidate();
			}

			return this._super.apply(this, arguments);
		}
	});

	/**
  * Checks if session has to be invalidated.
  *
  * @param {Number} status - status code of response
  * @param {Object} session - session service
  * @returns {Boolean} check result
  */
	function shouldInvalidateSession(status, session) {
		return status === 401 && session.get('isAuthenticated');
	}
});