define('supply-order-system/mirage/routes/socket', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {

	/* eslint require-jsdoc: "off" */

	exports['default'] = function (server) {
		server.get('/socket.io', function () {
			var myBlob = new Blob();
			return new _emberCliMirage['default'].Response(myBlob, { status: 200, 'statusText': 'OK' });
		});

		server.post('/socket.io', function () {
			var myBlob = new Blob();
			return new _emberCliMirage['default'].Response(myBlob, { status: 200, 'statusText': 'OK' });
		});
	};
});