define('supply-order-system/controllers/order', ['exports', 'ember', 'supply-order-system/mixins/controllers/track-package'], function (exports, _ember, _supplyOrderSystemMixinsControllersTrackPackage) {
	var get = _ember['default'].get;
	exports['default'] = _ember['default'].Controller.extend(_supplyOrderSystemMixinsControllersTrackPackage['default'], {
		application: _ember['default'].inject.controller(),
		session: _ember['default'].inject.service(),
		user: _ember['default'].inject.service(),
		trackingNumber: null,
		addingComment: false,
		comment: null,
		cancelledReasons: ['Duplicate', 'Incorrect Product', 'Incorrect Product Price', 'Incorrect Shipping Address', 'Incorrect Shipping Method', 'Incorrect Shipping Price'],
		cancelledReason: null,
		actions: {
			toggle: function toggle(property) {
				this.toggleProperty(property);
			},
			showModal: function showModal(modal) {
				_ember['default'].$('.ui.' + modal + '.modal').modal('show');
			},
			addTracking: function addTracking() {
				var _this = this;

				if (!this.get('application.canFulfill')) {
					this.notifications.error('You are not allowed to perform this action');
					return;
				}
				var tracking = this.store.createRecord('orderTracking', {
					value: this.get('trackingNumber'),
					orderId: this.get('model')
				});
				tracking.save().then(function () {
					_this.set('trackingNumber', null);
					_this.notifications.success('Tracking number added');
				})['catch'](function (err) {
					tracking.destroyRecord();
					_this.notifications.error(get(err, 'errors.message') || 'Error while saving tracking number');
				});
			},
			fulfillOrder: function fulfillOrder() {
				var _this2 = this;

				var order = this.get('model');
				if (!get(order, 'orderTrackings').get('length') && get(order, 'shippingMethodId.id') !== '5') {
					this.notifications.warning('You must include at least 1 tracking number before fulfilling the order');
					return;
				}
				this.loader.show('The order is being saved');
				// this.set('showFulfillModal', false);
				order.fulfill().then(function () {
					_this2.notifications.success('Order updated');
					_this2.transitionToRoute('orders.all');
				})['catch'](function (err) {
					_this2.notifications.error(get(err, 'errors.message') || 'Error while saving order');
				})['finally'](function () {
					return _this2.loader.clear();
				});
			},
			cancelOrder: function cancelOrder() {
				var _this3 = this;

				var order = this.get('model');
				this.loader.show('The order is being saved');
				order.cancel({ cancelledReason: get(this, 'cancelledReason') }).then(function () {
					_this3.notifications.success('Order updated');
					_this3.transitionToRoute('orders.all');
				})['catch'](function (err) {
					_this3.notifications.error(get(err, 'errors.message') || 'Error while saving order');
				})['finally'](function () {
					return _this3.loader.clear();
				});
			},
			addComment: function addComment() {
				var _this4 = this;

				this.set('addingComment', true);
				var comment = this.store.createRecord('orderComment', {
					comment: this.get('comment'),
					createdBy: this.get('user.userData').email,
					orderId: this.get('model')
				});
				comment.save().then(function () {
					_this4.set('comment', null);
					_this4.set('addingComment', false);
					_this4.notifications.success('Comment created');
				})['catch'](function (err) {
					_this4.set('addingComment', false);
					_this4.notifications.error(get(err, 'errors.message') || 'Error while creating comment');
					comment.deleteRecord();
				});
			}
		}
	});
});