define('supply-order-system/mirage/routes/index', ['exports', 'supply-order-system/mirage/routes/auth', 'supply-order-system/mirage/routes/orders', 'supply-order-system/mirage/routes/order-statuses', 'supply-order-system/mirage/routes/merchants', 'supply-order-system/mirage/routes/offices', 'supply-order-system/mirage/routes/brands', 'supply-order-system/mirage/routes/order-details', 'supply-order-system/mirage/routes/products', 'supply-order-system/mirage/routes/groups', 'supply-order-system/mirage/routes/socket'], function (exports, _supplyOrderSystemMirageRoutesAuth, _supplyOrderSystemMirageRoutesOrders, _supplyOrderSystemMirageRoutesOrderStatuses, _supplyOrderSystemMirageRoutesMerchants, _supplyOrderSystemMirageRoutesOffices, _supplyOrderSystemMirageRoutesBrands, _supplyOrderSystemMirageRoutesOrderDetails, _supplyOrderSystemMirageRoutesProducts, _supplyOrderSystemMirageRoutesGroups, _supplyOrderSystemMirageRoutesSocket) {
  Object.defineProperty(exports, 'auth', {
    enumerable: true,
    get: function get() {
      return _supplyOrderSystemMirageRoutesAuth['default'];
    }
  });
  Object.defineProperty(exports, 'orders', {
    enumerable: true,
    get: function get() {
      return _supplyOrderSystemMirageRoutesOrders['default'];
    }
  });
  Object.defineProperty(exports, 'orderStatuses', {
    enumerable: true,
    get: function get() {
      return _supplyOrderSystemMirageRoutesOrderStatuses['default'];
    }
  });
  Object.defineProperty(exports, 'merchants', {
    enumerable: true,
    get: function get() {
      return _supplyOrderSystemMirageRoutesMerchants['default'];
    }
  });
  Object.defineProperty(exports, 'offices', {
    enumerable: true,
    get: function get() {
      return _supplyOrderSystemMirageRoutesOffices['default'];
    }
  });
  Object.defineProperty(exports, 'brands', {
    enumerable: true,
    get: function get() {
      return _supplyOrderSystemMirageRoutesBrands['default'];
    }
  });
  Object.defineProperty(exports, 'orderDetails', {
    enumerable: true,
    get: function get() {
      return _supplyOrderSystemMirageRoutesOrderDetails['default'];
    }
  });
  Object.defineProperty(exports, 'products', {
    enumerable: true,
    get: function get() {
      return _supplyOrderSystemMirageRoutesProducts['default'];
    }
  });
  Object.defineProperty(exports, 'groups', {
    enumerable: true,
    get: function get() {
      return _supplyOrderSystemMirageRoutesGroups['default'];
    }
  });
  Object.defineProperty(exports, 'socket', {
    enumerable: true,
    get: function get() {
      return _supplyOrderSystemMirageRoutesSocket['default'];
    }
  });
});