define('supply-order-system/mixins/controllers/track-package', ['exports', 'ember', 'supply-order-system/enums/trackingApis'], function (exports, _ember, _supplyOrderSystemEnumsTrackingApis) {
	exports['default'] = _ember['default'].Mixin.create({
		actions: {
			track: function track(value) {
				var trackingApi = Object.values(_supplyOrderSystemEnumsTrackingApis['default']).find(function (selectedTrackingApi) {
					return selectedTrackingApi.patterns.find(function (pattern) {
						return RegExp(pattern).test(value);
					});
				});

				if (!trackingApi) {
					alert('Current tracking is not supported by any tracking api.');

					return;
				}

				window.open('' + trackingApi.url.replace('{{trackingNumber}}', value), '_blank');
			}
		}
	});
});