define('supply-order-system/models/brand', ['exports', 'ember-data'], function (exports, _emberData) {
	var Model = _emberData['default'].Model;
	var attr = _emberData['default'].attr;
	var hasMany = _emberData['default'].hasMany;
	exports['default'] = Model.extend({
		name: attr('string'),
		logoUrl: attr('string'),
		associate: attr('string'),
		chain: attr('string'),
		orders: hasMany('order')
	});
});