define('supply-order-system/models/group', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-api-actions'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships, _emberApiActions) {
	exports['default'] = _emberDataModel['default'].extend({
		displayName: (0, _emberDataAttr['default'])('string'),
		active: (0, _emberDataAttr['default'])('boolean', { defaultValue: false }),
		products: (0, _emberDataRelationships.hasMany)('product'),

		options: (function () {
			var products = this.get('products') || [];
			return {
				groupName: this.get('displayName'),
				options: products.map(function (x) {
					return x;
				})
			};
		}).property('displayName', 'products.@each'),

		link: (0, _emberApiActions.memberAction)({ path: 'link' }),
		unlink: (0, _emberApiActions.memberAction)({ path: 'unlink' })
	});
});