define('supply-order-system/formats', ['exports'], function (exports) {
	exports['default'] = {
		time: {
			hhmmss: {
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric'
			}
		},
		date: {
			hhmmss: {
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric'
			}
		},
		number: {
			EUR: {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			},
			USD: {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			}
		}
	};
});