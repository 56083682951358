define('supply-order-system/models/address', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
	exports['default'] = _emberDataModel['default'].extend({
		street1: (0, _emberDataAttr['default'])('string'),
		street2: (0, _emberDataAttr['default'])('string'),
		city: (0, _emberDataAttr['default'])('string'),
		state: (0, _emberDataAttr['default'])('string'),
		zip: (0, _emberDataAttr['default'])('string'),
		extendedZip: (0, _emberDataAttr['default'])('string'),
		name: (0, _emberDataAttr['default'])('string'),
		company: (0, _emberDataAttr['default'])('string'),
		phone: (0, _emberDataAttr['default'])('string'),
		email: (0, _emberDataAttr['default'])('string'),
		isValid: (0, _emberDataAttr['default'])('boolean'),
		isCommercial: (0, _emberDataAttr['default'])('boolean'),
		addressId: (0, _emberDataRelationships.belongsTo)('address', { inverse: 'candidates' }),
		candidates: (0, _emberDataRelationships.hasMany)('address', { inverse: 'addressId' }),
		shippingOrder: (0, _emberDataRelationships.hasMany)('shippingOrder')
	});
});