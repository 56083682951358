define('supply-order-system/mixins/controllers/orders-list-columns-mixin', ['exports', 'ember'], function (exports, _ember) {
	var get = _ember['default'].get;
	var computed = _ember['default'].computed;
	exports['default'] = _ember['default'].Mixin.create({
		columnFilters: {},
		columnSorts: {},
		columns: computed('model', function () {
			var DEFAULT_COLUMNS = [{
				propertyName: 'id',
				title: 'ID',
				mayBeHidden: false,
				theadClassName: 'collapsing',
				routeName: this.routeName,
				filters: this.columnFilters.id
			}, {
				propertyName: 'brandId',
				filteredBy: 'brandId',
				title: 'Brand',
				template: 'ember-common-table/brand',
				filterWithSelect: true,
				filterSelectDisplayProperty: 'name',
				type: 'number',
				predefinedFilterOptions: get(this, 'model.brands').map(function (x) {
					return { label: get(x, 'name'), value: get(x, 'id') };
				}),
				filterString: get(this, 'columnFilters')['brand.name']
			}, {
				propertyName: 'officeId',
				filteredBy: 'officeId',
				template: 'ember-common-table/office',
				title: 'Office',
				filters: this.columnFilters['officeId-id']
			}, {
				propertyName: 'createdBy',
				title: 'Created By',
				filters: this.columnFilters.createdBy
			}, {
				propertyName: 'createdAt',
				title: 'Created At',
				disableFiltering: true,
				disableSorting: true
			}];

			var MID_COLUMN = {
				propertyName: 'mid',
				filteredBy: 'mid',
				template: 'ember-common-table/mid',
				title: 'MID',
				filters: this.columnFilters['mid-id']
			};

			var ORDER_STATUS_COLUMN = {
				propertyName: 'orderStatusId',
				filteredBy: 'orderStatusId',
				title: 'Status',
				template: 'ember-common-table/order/order-status-id',
				filterWithSelect: true,
				type: 'number',
				predefinedFilterOptions: get(this, 'model.orderStatuses').map(function (x) {
					return { label: get(x, 'displayName'), value: get(x, 'id') };
				}),
				filterString: get(this, 'columnFilters')['orderStatusId.displayName']
			};

			var FIRST_ITEM_COLUMN = {
				propertyName: 'orderDetails.firstObject.productId.displayName',
				title: 'First Item In Cart',
				disableFiltering: true,
				disableSorting: true
			};

			if (!this.get('isIsoPage')) {
				DEFAULT_COLUMNS.splice(2, 0, MID_COLUMN);
			}

			if (!this.get('isApprovalPage')) {
				DEFAULT_COLUMNS.splice(1, 0, ORDER_STATUS_COLUMN);
				DEFAULT_COLUMNS.splice(7, 0, FIRST_ITEM_COLUMN);
			}

			return DEFAULT_COLUMNS;
		}),
		actions: {
			displayDataChanged: function displayDataChanged(state) {
				var _this = this;

				Object.keys(state.columnFilters).forEach(function (x) {
					var name = x.replace('.', '-');
					_this.columnFilters[name] = state.columnFilters[x];
				});
				Object.keys(state.sort).forEach(function (x) {
					var name = x.replace('.', '-');
					_this.columnSorts[name] = state.sort[x];
				});
			}
		}
	});
});