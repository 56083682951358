define('supply-order-system/controllers/admin/group-mappings', ['exports', 'ember'], function (exports, _ember) {
	var computed = _ember['default'].computed;
	exports['default'] = _ember['default'].Controller.extend({
		expandedRowTemplate: 'ember-common-table/admin/group-mappings/products',
		columns: computed('model', function () {
			return [{
				mayBeHidden: false,
				template: 'components/ht-table/expand-row-cell',
				className: 'collapsing'
			}, {
				propertyName: 'displayName',
				title: 'Name'
			}];
		}),
		productsColumns: computed('model', function () {
			return [{
				propertyName: 'displayName',
				title: 'Name'
			}, {
				propertyName: 'model',
				title: 'Model#'
			}, {
				mayBeHidden: false,
				template: 'ember-common-table/admin/group-mappings/link-unlink',
				className: 'collapsing'
			}];
		})
	});
});