define('supply-order-system/router', ['exports', 'ember', 'supply-order-system/config/environment', 'ember-common/utils/router-setup'], function (exports, _ember, _supplyOrderSystemConfigEnvironment, _emberCommonUtilsRouterSetup) {

	var Router = _ember['default'].Router.extend({
		location: _supplyOrderSystemConfigEnvironment['default'].locationType,
		rootURL: _supplyOrderSystemConfigEnvironment['default'].rootURL
	});

	Router.map(function () {
		(0, _emberCommonUtilsRouterSetup['default'])(this);
		this.route('login', { path: '/login' });
		this.route('index', { path: '/' });
		this.route('notifications');
		this.route('merchants');
		this.route('merchant', { path: '/merchants/:id' });
		this.route('orders', function () {
			this.route('all');
			this.route('merchant');
			this.route('iso');
		});
		this.route('order', { path: '/orders/:id' });
		this.route('approvals', { path: '/orders/approvals' });
		this.route('approvals', { path: '/orders/approvals' }, function () {
			this.route('all');
			this.route('merchant');
			this.route('iso');
		});
		this.route('approval', { path: '/orders/approvals/:id' });
		this.route('invoice', { path: '/orders/:id/invoice' });
		this.route('admin', function () {
			this.route('products');
			this.route('groups');
			this.route('equipment');
			this.route('group-mappings');
		});
	});

	exports['default'] = Router;
});