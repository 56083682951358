define('supply-order-system/controllers/admin/groups', ['exports', 'ember'], function (exports, _ember) {
	var set = _ember['default'].set;
	var computed = _ember['default'].computed;
	exports['default'] = _ember['default'].Controller.extend({
		expandedRowTemplate: 'ember-common-table/admin/groups/edit',
		columns: computed('model', function () {
			return [{
				template: 'ember-common-table/admin/groups/name-column',
				propertyName: 'displayName',
				title: 'Name'
			}, {
				mayBeHidden: false,
				template: 'ember-common-table/edit-row',
				className: 'collapsing'
			}];
		}),
		actions: {
			showModal: function showModal(modal) {
				set(this, 'newGroup', {});
				_ember['default'].$('.ui.' + modal + '.modal').modal('show');
			}
		}
	});
});