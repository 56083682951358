define('supply-order-system/controllers/merchants', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
	exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
		search: null,
		isProcessing: false,
		disableSearch: _ember['default'].computed('search', 'isProcessing', function () {
			return this.get('search') && !this.get('isProcessing') ? false : true;
		}),
		errorMessage: 'Not found',
		merchantNotFound: false,
		actions: {
			lookupFileId: function lookupFileId() {
				var _this = this;

				if (!this.get('search')) {
					return;
				}
				this.set('isProcessing', true);
				if (this.get('search').length > 5) {
					this.store.find('merchant', this.get('search')).then(function (data) {
						_this.transitionToRoute('merchant', data.id);
					})['catch'](function () {
						_ember['default'].$('.ui.modal.merchantNotFound').modal('show');
					}).then(function () {
						_this.set('isProcessing', false);
					});
				} else {
					this.store.find('office', this.get('search')).then(function (data) {
						_this.transitionToRoute('merchant', data.id);
					})['catch'](function () {
						_ember['default'].$('.ui.modal.officeNotFound').modal('show');
					}).then(function () {
						_this.set('isProcessing', false);
					});
				}
			}
		}
	});
});