define('supply-order-system/models/office', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
	exports['default'] = _emberDataModel['default'].extend({
		hKey: (0, _emberDataAttr['default'])('string'),
		isoName: (0, _emberDataAttr['default'])('string'),
		isoAddress1: (0, _emberDataAttr['default'])('string'),
		isoAddress2: (0, _emberDataAttr['default'])('string'),
		isoCity: (0, _emberDataAttr['default'])('string'),
		isoState: (0, _emberDataAttr['default'])('string'),
		isoZip: (0, _emberDataAttr['default'])('string'),
		isoPaymentMethod: (0, _emberDataAttr['default'])('string'),
		orders: (0, _emberDataRelationships.hasMany)('order')
	});
});