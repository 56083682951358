define('supply-order-system/services/user', ['exports', 'ember', 'supply-order-system/config/environment'], function (exports, _ember, _supplyOrderSystemConfigEnvironment) {
	exports['default'] = _ember['default'].Service.extend({
		ajax: _ember['default'].inject.service(),
		userData: null,
		userName: _ember['default'].computed('userData.{firstName,lastName}', function () {
			return this.get('userData.firstName') + ' ' + this.get('userData.lastName');
		}),
		fetchUserData: function fetchUserData(token) {
			var _this = this;

			return this.get('ajax').request(_supplyOrderSystemConfigEnvironment['default'].host + '/api/v1/users/self', {
				headers: {
					'auth-token': token
				}
			}).then(function (_ref) {
				var user = _ref.user;

				_this.set('userData', user);
			});
		},
		belongsTo: function belongsTo(groups) {
			return this.get('userData.groups').includes(groups);
		}
	});
});