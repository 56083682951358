define('supply-order-system/routes/admin/group-mappings', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	var get = _ember['default'].get;
	var RSVP = _ember['default'].RSVP;
	var Route = _ember['default'].Route;
	exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
		titleToken: function titleToken() {
			return 'Group mappings';
		},
		model: function model() {
			return RSVP.hash({
				groups: this.store.findAll('group'),
				products: this.store.findAll('product')
			});
		},
		actions: {
			link: function link(group, product) {
				var _this = this;

				return group.link({ productId: product.id }).then(function () {
					get(group, 'products').pushObject(product);
				})['catch'](function (err) {
					_this.notifications.error(err.errors.message || 'An unknown error occurred');
				});
			},
			unlink: function unlink(group, product) {
				var _this2 = this;

				return group.unlink({ productId: product.id }).then(function () {
					get(group, 'products').removeObject(product);
				})['catch'](function (err) {
					_this2.notifications.error(err.errors.message || 'An unknown error occurred');
				});
			}
		}
	});
});