define('supply-order-system/components/animated-options', ['exports', 'ember', 'ember-power-select/components/power-select/options'], function (exports, _ember, _emberPowerSelectComponentsPowerSelectOptions) {
	exports['default'] = _emberPowerSelectComponentsPowerSelectOptions['default'].extend({
		animationRules: _ember['default'].computed(function () {
			return function () {
				this.transition(this.toValue(function (newOptions, oldOptions) {
					return oldOptions === safeGet(newOptions, 0, 'parentLevel', 'options');
				}), this.use('toLeft'), this.reverse('toRight'));
			};
		}),

		didReceiveAttrs: function didReceiveAttrs() {
			this._super.apply(this, arguments);
			this.set('enableGrowth', !this.get('options.fromSearch'));
		}
	});

	/**
  * ...
  *
  * @param {any} base ...
  * @param {any} keys ...
  * @returns {any} ...
  */
	function safeGet(base) {
		for (var _len = arguments.length, keys = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
			keys[_key - 1] = arguments[_key];
		}

		while (base && keys.length > 0) {
			base = base[keys.shift()];
		}
		return base;
	}
});