define('supply-order-system/mirage/factories/order', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
	exports['default'] = _emberCliMirage.Factory.extend({
		mid: function mid() {
			return _emberCliMirage.faker.random.number();
		},
		officeId: function officeId() {
			return _emberCliMirage.faker.random.number();
		},
		shippingAttention: function shippingAttention() {
			return _emberCliMirage.faker.random.name();
		},
		shippingTo: function shippingTo() {
			return _emberCliMirage.faker.company();
		},
		shippingAddress1: function shippingAddress1() {
			return _emberCliMirage.faker.address.streetAddress();
		},
		shippingAddress2: function shippingAddress2() {
			return _emberCliMirage.faker.address.streetAddress();
		},
		shippingCity: function shippingCity() {
			return _emberCliMirage.faker.address.city();
		},
		shippingState: function shippingState() {
			return _emberCliMirage.faker.address.state();
		},
		shippingZip: function shippingZip() {
			return _emberCliMirage.faker.address.zipCode().substring(0, 5);
		},
		shippingZipExtended: function shippingZipExtended() {
			return _emberCliMirage.faker.address.zipCode().substring(0, 4);
		},
		isAddressValidated: function isAddressValidated() {
			return false;
		},
		discount: function discount() {
			return _emberCliMirage.faker.random.number();
		},
		quantumViewRecipient: function quantumViewRecipient() {
			return _emberCliMirage.faker.lorem();
		},
		fulfilledBy: function fulfilledBy() {
			return _emberCliMirage.faker.lorem.word();
		},
		fulfilledAt: function fulfilledAt() {
			return _emberCliMirage.faker.date.past();
		},
		approvedBy: function approvedBy() {
			return _emberCliMirage.faker.name.firstName();
		},
		approvedAt: function approvedAt() {
			return _emberCliMirage.faker.date.past();
		},
		deniedReason: function deniedReason() {
			return _emberCliMirage.faker.lorem().word();
		},
		cancelledBy: function cancelledBy() {
			return _emberCliMirage.faker.name.firstName();
		},
		cancelledAt: function cancelledAt() {
			return _emberCliMirage.faker.date.past();
		},
		cancelledReason: function cancelledReason() {
			return _emberCliMirage.faker.lorem().word();
		},
		isIsoOrder: function isIsoOrder() {
			return _emberCliMirage.faker.random.boolean();
		},
		isBilled: function isBilled() {
			return _emberCliMirage.faker.random.boolean();
		},
		billedAt: function billedAt() {
			return _emberCliMirage.faker.date.past();
		},
		createdBy: function createdBy() {
			return _emberCliMirage.faker.name.firstName();
		},
		isGraphite: function isGraphite() {
			return _emberCliMirage.faker.random.boolean();
		},
		shippingPrice: function shippingPrice() {
			return _emberCliMirage.faker.random.number() + ' ' + _emberCliMirage.faker.finance.currencySymbol();
		},
		bankAccountID: function bankAccountID() {
			return '' + _emberCliMirage.faker.random.number();
		}
	});
});