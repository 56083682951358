define('supply-order-system/routes/admin/products', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	var get = _ember['default'].get;
	var set = _ember['default'].set;
	var Route = _ember['default'].Route;
	exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
		titleToken: function titleToken() {
			return 'Products';
		},
		model: function model() {
			return this.store.findAll('product');
		},
		setupController: function setupController(controller) {
			this._super.apply(this, arguments);
			set(this, 'controller', controller);
		},
		actions: {
			save: function save(product) {
				var _this = this;

				return product.save().then(function () {
					return _this.notifications.success('Product saved');
				})['catch'](function (err) {
					return _this.notifications.error(get(err, 'errors.message'));
				});
			},
			reset: function reset(product) {
				return product.rollbackAttributes();
			},
			create: function create() {
				var _this2 = this;

				return this.store.createRecord('product', get(this, 'controller.newProduct')).save().then(function () {
					return _this2.notifications.success('Product saved');
				})['catch'](function (err) {
					return _this2.notifications.error(get(err, 'errors.message'));
				});
			}
		}
	});
});