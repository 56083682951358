define('supply-order-system/controllers/notifications', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
	exports['default'] = _ember['default'].Controller.extend({
		filteredMessages: _ember['default'].computed.filterBy('model', 'isRead', false),
		sortedMessages: _ember['default'].computed.sort('filteredMessages', function (a, b) {
			if ((0, _moment['default'])(_ember['default'].get(a, 'createdAt')) < (0, _moment['default'])(_ember['default'].get(b, 'createdAt'))) {
				//returns afternoon timings before morning timings
				//api docs says return -1, when 'a' should come before 'b'
				return 1;
			} else if ((0, _moment['default'])(_ember['default'].get(a, 'createdAt')) > (0, _moment['default'])(_ember['default'].get(b, 'createdAt'))) {
				//returns morning timings before afternoon timings
				//api doc says return 1 when 'a' should come after 'b'
				//http://emberjs.com/api/#method_computed_sort
				return -1;
			}
			return 0;
		}),
		actions: {
			markAllRead: function markAllRead() {
				if (this.get('model')) {
					this.get('model').forEach(function (notification) {
						notification.set('isRead', true);
						notification.save();
					});
				}
			},
			markRead: function markRead(item) {
				item.set('isRead', true);
				item.save();
			}
		}
	});
});