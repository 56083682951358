define('supply-order-system/helpers/text-trim', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Helper.extend({
		compute: function compute(hash, namedArgs) {
			if (!Number.isInteger(namedArgs.limit) || typeof namedArgs.text !== 'string') {
				return '';
			}

			if (namedArgs.text.length <= namedArgs.limit) {
				return namedArgs.text;
			}

			return namedArgs.text.substring(0, namedArgs.limit) + '...';
		}
	});
});