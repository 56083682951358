define('supply-order-system/controllers/merchant', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
	var get = _ember['default'].get;
	var set = _ember['default'].set;
	var setProperties = _ember['default'].setProperties;
	var computed = _ember['default'].computed;
	var inject = _ember['default'].inject;
	var observer = _ember['default'].observer;

	var shippingMethodMappings = {
		1: '3197bb2e-9ee6-4d93-a9c1-646f33dbc0e3',
		2: '07b00c24-b238-4a3b-a2e4-28fab0394531',
		3: '890e9cee-0d4c-400b-bbfd-c28724766b64',
		4: '41ea0f6a-b2df-4532-a4a8-cacbad713477',
		6: '2a8d5ce8-3729-475b-ac77-d7d6131087c2'
	};

	exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
		application: inject.controller(),
		session: inject.service(),
		selectedSupply: null,
		supplyQuantity: null,
		canChooseBankAccount: false,
		removeSaturdayDelivery: computed('model', function () {
			return new Date().getDay() !== 5;
		}),
		shippingMethodSorting: ['id'],
		sortedShippingMethods: computed.sort('model.shippingMethods', 'shippingMethodSorting'),
		isValidDiscount: computed('model.order.discount', 'model.order.total', 'model.order.orderDetails.@each', function () {
			var priceRegex = /^[\d]+(\.\d{0,2})?$|^(\.\d{1,2})?$/;
			if (get(this, 'model.order.discount') === null || get(this, 'model.order.discount') === '' || !priceRegex.test(get(this, 'model.order.discount')) || parseFloat(get(this, 'model.order.discount')) > get(this, 'model.order.total')) {
				return false;
			}
			return true;
		}),
		disableCreateOrder: computed('model.order.orderDetails', 'hasShippingError', 'model.order.billingMethodId', 'isValidDiscount', 'model.order.bankAccountID', 'canChooseBankAccount', function () {
			if (get(this, 'model.order.orderDetails.length') === 0 || get(this, 'hasShippingError') || get(this, 'isValidDiscount') === false || !get(this, 'model.order.billingMethodId.id') || !get(this, 'model.order.shippingOrderId') && get(this, 'model.order.shippingMethodId.id') != '5' || !get(this, 'model.order.addressId') || get(this, 'canChooseBankAccount') && !get(this, 'model.order.bankAccountID')) {
				return true;
			}
			return false;
		}),
		disableAdd: computed('selectedSupply', 'supplyQuantity', 'supplyQuantity', function () {
			var quantityRegex = /^[1-9][0-9]*$/;
			if (!get(this, 'selectedSupply') || !get(this, 'supplyQuantity') || get(this, 'supplyQuantity') <= 0 || !quantityRegex.test(get(this, 'supplyQuantity'))) {
				return true;
			}
			return false;
		}),
		shippingStateObserver: observer('model.order.shippingState', function () {
			if ((get(this, 'model.order.shippingState') === 'AK' || get(this, 'model.order.shippingState') === 'HI') && get(this, 'model.order.shippingMethodId.id') === '1') {
				set(this, 'model.order.shippingMethodId', null);
			}
		}),
		fetchAllShippingPrices: function fetchAllShippingPrices() {
			var _this = this;

			var order = get(this, 'model.order');
			if (get(order, 'shippingMethodId.id') === '5') {
				var total = 0;
				get(order, 'orderDetails').forEach(function (x) {
					total += get(x, 'productId.cost') * get(x, 'quantity');
				});

				setProperties(order, {
					shippingPrice: total * 0.1,
					shippingOrderId: null
				});
				return;
			}
			set(this, 'hasShippingError', false);
			if (!get(order, 'shippingAddress1') || !get(order, 'shippingCity') || !get(order, 'shippingState') || !get(order, 'shippingZip')) {
				return;
			}

			if (!get(order, 'shippingMethodId.id')) {
				return;
			}

			var details = get(this, 'model.order.orderDetails');

			if (!get(details, 'length')) {
				return;
			}
			this.loader.show('Please be patient while we recalculate all quotes');

			var payload = {
				shippingOrder: {
					serviceId: shippingMethodMappings[get(order, 'shippingMethodId.id')],
					addressId: get(order, 'addressId'),
					products: details.filter(function (x) {
						return !get(x, 'isFreeShipping');
					}).map(function (x) {
						return { productId: get(x, 'productId.id'), quantity: get(x, 'quantity') };
					})
				}
			};

			_ember['default'].$.ajax({
				url: window.location.origin + '/api/v1/shippingOrders',
				type: 'POST',
				data: JSON.stringify(payload),
				contentType: 'application/json',
				headers: {
					'auth-token': get(this, 'session.data.authenticated').token
				},
				success: function success(data) {
					setProperties(order, {
						shippingPrice: data.order.rate,
						addressId: data.order.addressId,
						shippingOrderId: data.order.id
					});
					_this.loader.clear();
				},
				error: function error(err) {
					_this.loader.clear();
					_this.notifications.error(err.errors.message || 'Error retrieving shipping price. Please check the shipping address and method');
					// count++;
					// set(this, 'hasShippingError', true);
					// if (count === get(details, 'length')) {
					// 	this.loader.clear();
					// }
				}
			});
		},
		updateAllShippingPrices: observer('model.order.shippingMethodId', 'model.order.shippingAddress1', 'model.order.shippingAddress2', 'model.order.shippingCity', 'model.order.shippingState', 'model.order.shippingZip', 'model.order.shippingZipExtended', 'model.order.shippingMethodId', function () {
			var _this2 = this;

			// http://emberjs.com/api/classes/Ember.run.html#method_debounce
			// This would get fired after the order is saved, so we check if the record is saved or not first
			if (get(this, 'model.order.isNew')) {
				_ember['default'].run.debounce(this, function () {
					var model = get(_this2, 'model.order');
					var data = {
						address1: get(model, 'shippingAddress1'),
						address2: get(model, 'shippingAddress2'),
						city: get(model, 'shippingCity'),
						state: get(model, 'shippingState'),
						zip: get(model, 'shippingZip'),
						extendedZip: get(model, 'shippingZipExtended'),
						name: get(model, 'shippingTo'),
						company: get(model, 'shippingAttention')
					};

					_ember['default'].$.ajax({
						url: window.location.origin + '/api/v1/ups/validate',
						type: 'GET',
						data: data,
						accepts: 'application/json',
						headers: {
							'auth-token': get(_this2, 'session.data.authenticated').token
						}
					}).done(function (result) {
						setProperties(model, {
							addressId: result.address.id,
							isAddressValidated: result.address.isValid
						});
						_this2.fetchAllShippingPrices();
					});
				}, 500);
			}
		}),
		unvalidateOrder: _ember['default'].observer('model.order.shippingAddress1', 'model.order.shippingAddress2', 'model.order.shippingCity', 'model.order.shippingState', 'model.order.shippingZip', 'model.order.shippingZipExtended', function () {
			get(this, 'model.order').set('isAddressValidated', false);
		}),
		updateAddressList: function updateAddressList() {
			var _this3 = this;

			// Get list of valid addresses from backend
			return new Promise(function (resolve, reject) {
				var model = get(_this3, 'model.order');
				set(_this3, 'addressList', null);
				var data = {
					address1: get(model, 'shippingAddress1'),
					address2: get(model, 'shippingAddress2'),
					city: get(model, 'shippingCity'),
					state: get(model, 'shippingState'),
					zip: get(model, 'shippingZip'),
					extendedZip: get(model, 'shippingZipExtended'),
					name: get(model, 'shippingTo'),
					company: get(model, 'shippingAttention')
				};

				_ember['default'].$.ajax({
					url: window.location.origin + '/api/v1/ups/validate',
					type: 'GET',
					data: data,
					accepts: 'application/json',
					headers: {
						'auth-token': get(_this3, 'session.data.authenticated').token
					}
				}).done(function (result) {
					if (result.address) {
						if (result.address.isValid) {
							// This is a valid address
							model.setProperties({
								shippingAddress1: result.address.street1,
								shippingAddress2: result.address.street2,
								shippingCity: result.address.city,
								shippingState: result.address.state,
								shippingZip: result.address.zip,
								shippingZipExtended: result.address.extendedZip
							});
							set(_this3, 'addressList', []);
							resolve();
						} else if (result.address.candidates.length > 0) {
							// This address has candidates
							set(_this3, 'addressList', result.address.candidates);
							resolve();
						} else {
							// Not valid and no candidates
							_this3.notifications.info('No results found for this address');
							reject();
						}
					} else {
						reject('An unknown error occurred');
					}
				}).fail(function (err) {
					reject(err.responseJSON && err.responseJSON.errors ? err.responseJSON.errors.message : 'A transport error occurred');
				});
			});
		},
		setDefaultValues: function setDefaultValues() {
			set(this, 'canChooseBankAccount', false);
			set(this, 'model.order.bankAccountID', null);
		},
		actions: {
			showModal: function showModal(modal) {
				_ember['default'].$('.ui.' + modal + '.modal').modal('show');
			},
			showValidation: function showValidation() {
				var _this4 = this;

				this.loader.show('Asking UPS to validate this address...');
				return this.updateAddressList().then(function () {
					if (get(_this4, 'addressList').length > 0) {
						_ember['default'].$('.ui.modal-validation.modal').modal('show');
					} else {
						set(_this4, 'model.order.isAddressValidated', true);
						_this4.notifications.info('Address is valid, updated formatting');
					}
				})['catch'](function (err) {
					if (err) {
						_this4.notifications.error(err);
					}
				}).then(function () {
					_this4.loader.clear();
				});
			},
			selectAddress: function selectAddress(address) {
				if (address) {
					var order = get(this, 'model.order');
					setProperties(order, {
						shippingAddress1: address.street1,
						shippingAddress2: address.street2,
						shippingCity: address.city,
						shippingState: address.state,
						shippingZip: address.zip,
						shippingZipExtended: address.extendedZip
					});
					set(order, 'isAddressValidated', true);
				}
			},
			selectSupply: function selectSupply(supply) {
				setProperties(this, {
					selectedSupply: supply,
					supplyQuantity: get(this, 'supplyQuantity') || 1
				});
			},
			removeFromCart: function removeFromCart(item) {
				item.deleteRecord();
				this.fetchAllShippingPrices();
			},
			addToCart: function addToCart() {
				var order = get(this, 'model.order');
				var selectedSupply = get(this, 'selectedSupply');

				this.store.createRecord('orderDetail', {
					quantity: get(this, 'supplyQuantity'),
					cost: get(selectedSupply, 'cost'),
					price: get(selectedSupply, 'price'),
					orderId: order,
					productId: get(this, 'selectedSupply'),
					bankAccountID: null
				});
				this.setProperties({
					selectedSupply: null,
					supplyQuantity: null
				});
				this.fetchAllShippingPrices();
			},
			setShippingToDba: function setShippingToDba() {
				var merchant = get(this, 'model.merchant');
				var order = get(this, 'model.order');
				order.setProperties({
					shippingTo: get(merchant, 'ownerFirstName') + ' ' + get(merchant, 'ownerLastName'),
					shippingAddress1: get(merchant, 'dbaAddress1'),
					shippingAddress2: get(merchant, 'dbaAddress2'),
					shippingCity: get(merchant, 'dbaCity'),
					shippingState: get(merchant, 'dbaState'),
					shippingZip: get(merchant, 'dbaZip'),
					shippingZipExtended: ''
				});
			},
			setShippingToIso: function setShippingToIso() {
				var office = undefined;
				if (get(this, 'model.office')) {
					office = get(this, 'model.office');
				} else {
					office = get(this, 'model.merchant');
				}

				var order = get(this, 'model.order');
				order.setProperties({
					shippingTo: get(office, 'isoName'),
					shippingAddress1: get(office, 'isoAddress1'),
					shippingAddress2: get(office, 'isoAddress2'),
					shippingCity: get(office, 'isoCity'),
					shippingState: get(office, 'isoState'),
					shippingZip: get(office, 'isoZip'),
					shippingZipExtended: ''
				});
			},
			discard: function discard() {
				this.transitionToRoute('merchants');
			},
			submit: function submit() {
				var _this5 = this;

				this.loader.show('Your order is being created');
				get(this, 'model.order').save().then(function () {
					get(_this5, 'model.order.orderDetails.content').filterBy('isNew', true).every(function (detail) {
						detail.destroyRecord();
					});
					_this5.notifications.success('Order created');
					_this5.transitionToRoute('merchants');
				})['catch'](function (err) {
					_this5.notifications.error(get(err, 'errors.message') || 'Error while creating order');
				})['finally'](function () {
					return _this5.loader.clear();
				});
			},
			checkDiscount: function checkDiscount() {
				if (!get(this, 'isValidDiscount')) {
					set(this, 'model.order.discount', 0);
				}
			},
			toggleQuoteDisplay: function toggleQuoteDisplay(value) {
				set(this, 'showQuote', value);
			},
			selectBankAccount: function selectBankAccount(bankAccountId) {
				set(this, 'model.order.bankAccountID', bankAccountId);
			},
			changeBillTo: function changeBillTo(billTo) {
				set(this, 'model.order.billingMethodId', billTo);
				if (billTo.id == 1 && get(this, 'model.bankAccounts.length') > 1) {
					set(this, 'canChooseBankAccount', true);
				} else {
					this.setDefaultValues();
				}
			}
		}
	});
});