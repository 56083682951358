define('supply-order-system/routes/application', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		title: function title(tokens) {
			tokens = _ember['default'].makeArray(tokens);
			return tokens.length > 0 ? tokens.join(' | ') : 'Supply Order System';
		},
		intl: _ember['default'].inject.service(),
		beforeModel: function beforeModel() {
			return this.get('intl').setLocale('en-us');
		},
		activate: function activate() {
			this.notifications.setDefaultAutoClear(true);

			// Check if the browser supports notifications
			if ('Notification' in window) {
				if (Notification.permission === 'denied' || Notification.permission === 'default') {
					Notification.requestPermission();
				}
			}
		},
		actions: {
			authorizationFailed: function authorizationFailed() {
				this.notifications.error('Your session has expired');
				this.transitionTo(_ember['default'].SimpleAuth.loginRoute);
			}
		}
	});
});