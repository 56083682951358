define('supply-order-system/mirage/config', ['exports', 'supply-order-system/mirage/routes'], function (exports, _supplyOrderSystemMirageRoutes) {

	/* eslint require-jsdoc: "off" */

	exports['default'] = function () {
		_supplyOrderSystemMirageRoutes['default'].socket(this);

		this.namespace = '/api/v1';

		_supplyOrderSystemMirageRoutes['default'].auth(this);
		_supplyOrderSystemMirageRoutes['default'].brands(this);
		_supplyOrderSystemMirageRoutes['default'].orders(this);
		_supplyOrderSystemMirageRoutes['default'].orderStatuses(this);
		_supplyOrderSystemMirageRoutes['default'].offices(this);
		_supplyOrderSystemMirageRoutes['default'].merchants(this);
		_supplyOrderSystemMirageRoutes['default'].orderDetails(this);
		_supplyOrderSystemMirageRoutes['default'].groups(this);
		_supplyOrderSystemMirageRoutes['default'].products(this);
	};
});